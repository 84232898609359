import { Layout, Alert } from "antd";
import "../../assets/AppLottery.css";
import { Outlet } from "react-router-dom";
import SideBarLottery from "./side-bar/sideBarLottery.jsx";
import MainSkelethon from "../skelethon/index.jsx";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import useAlert from "../../hooks/useAlert.js";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import useEffectOnce from "../../hooks/useHook.js";
import { useMemo } from "react";
import axios from "axios";
import useAuthentication from "../../hooks/useAuthentication.js";
import LotteryProvider from "./lotteryProvider.jsx";
import useCollapse from "../../hooks/useCollapse.js";

const { Sider } = Layout;

const Lottery = () => {
  const { isAuthenticate, setCredential, getCurrentUser } = useAuthentication();
  const { alert, callAlert } = useAlert();
  const navigate = useNavigate();
  const { setUser, user, connection } = useGlobalContext();
  const { collapsed, toggleSider } = useCollapse();

  useEffectOnce(() => {
    if (!isAuthenticate) {
      navigate("/login");
      return;
    }

    const user = getCurrentUser();
    if (isEmpty(user)) return;
    setUser(user);

    connection?.stop();
  });

  useMemo(() => {
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        handlerStatusCode(error);
        // return Promise.reject(error);
      }
    );
    // eslint-disable-next-line
  }, [callAlert]);

  const clearCredential = () => {
    setUser(undefined);
    setCredential(undefined);
  };

  const handlerStatusCode = (error) => {
    const {
      message,
      response: { status, data },
    } = error;
    switch (status) {
      case 401:
        clearCredential();
        navigate("/login");
        return;
      case 403:
        break;
      default:
        break;
    }

    const mess = isEmpty(data)
      ? message
      : isEmpty(data.message)
        ? message
        : data.message;
    callAlert().error(mess);
  };

  return (
    <Layout>
      {alert !== undefined && (
        <Alert
          className="alert"
          message={alert.message}
          type={alert.type}
          showIcon
        />
      )}
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className="main-color min-height"
        width={300}
        collapsed={collapsed}
        onCollapse={() => toggleSider()}
      >
        <div className={!collapsed && "sider-fixed"}>
          {user ? <SideBarLottery /> : <MainSkelethon />}
        </div>
      </Sider>
      <Layout>
        <LotteryProvider>
          <Outlet />
        </LotteryProvider>
      </Layout>
    </Layout>
  );
};

export default Lottery;
